import React from 'react';

const Slider = () => {
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slide_content">
                                <h5 className="sub_heading">CALL US ANYTIME<span> +254 (0)78 4002 044</span></h5>
                                <h2 className="heading">Roophix</h2>
                                <p className="desc">We supply quality construction materials, full range of safety equipment  as well as tools and machines for wood workers</p>
                                <div className="slider_button">
                                    <a href="#" className="button">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-flex align-items-end">
                            <div className="layer_object">
                            <img src="images/slider/layer_2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;