import React from 'react';

const TopFeatures = () => {
    return (
        <div className="top_feature">
            <div className="container">
                <div style={{marginTop:-100}} className="content_inner">
                    <h1>We are safebuild experts. We thrive in quality service, Diversity and Completeness</h1>
                </div>
            </div>
        </div>
    );
};

export default TopFeatures;