import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, List, Typography } from '@material-tailwind/react';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, useMediaQuery } from '@mui/material';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Option,
  Select,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import ReactQuill from 'react-quill'
import { FadeLoader } from 'react-spinners'
import 'react-quill/dist/quill.snow.css';
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const Align = ReactQuill.Quill.import('attributors/style/align');
ReactQuill.Quill.register(Align, true);


const toolbarOptions = [
  [{ 'font': [] }], // Font family options
  [{ 'header': [1, 2, 3, 4, 5, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['link'],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'align': [] }], // Alignment options
  ['clean'],
];


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'blue',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Overview({ eventID, products, productImages }) {
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState('');
    const [previewURL, setPreviewURL] = useState('');
    const [medialLoader, setMediaLoader] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const [finalOpened, setFinalOpened] = React.useState(0);
    const [finalTitle, setFinalTitle] = React.useState('');
    const [finalPrice, setFinalPrice] = React.useState('');
    const [finalShortDesc, setFinalShortDesc] = React.useState('');
    const [finalCategory, setFinalCategory] = React.useState('');
    const [finalPdf, setFinalPdf] = React.useState('');
    const [categories, setCategories] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const [productImagesModal, setProductImagesModal] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);


    useEffect(() => {
      const fetchCategories = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            "https://roophix.com/backend/php/getProductCategory.php"
          );
          const data = await response.json();
          const uniqueTitles = Array.from(
            new Set(data?.map((item) => item?.title))
          ); // Assuming each item has a 'title' field
          setCategories(uniqueTitles);
          setLoading(false);
        } catch (error) {
          toast.error("Failed to fetch categories");
          setLoading(false);
        }
      };
  
      fetchCategories();
    }, []);
  


    const handleOnChangeProductCategory = (category) => {
      setFinalCategory(category);
    };

    const openModalFun = (title, shortDesc, pdf, price, category) => {
      setModalShow(true);
      setFinalTitle(title);
      setFinalShortDesc(shortDesc)
      setFinalPdf(pdf)
      setFinalPrice(price)
      setFinalCategory(category)
    }

  
  const filteredEvents = products.filter(event => event.id === parseInt(eventID));

  const renderHTML = () => {
    return { __html: filteredEvents[0]?.description };
  };

  


  const handleFileSelect = (event) => {
    const files = event.target.files;
    const urls = [];
    setSelectedFiles(files);

    // Check if files are selected
    if (files.length === 0) {
      toast.error("Please select at least one image.", {
        position: "top-center",
      });
      return;
    }

    // Loop through each selected file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Validate file type (optional)
      if (!file.type.startsWith("image/")) {
        toast.error("Please select only image files.", {
          position: "top-center",
        });
        return;
      }
      // Validate file size (optional)
      if (file.size > 5242880) {
        // 5 MB limit
        toast.error("Image size exceeds the limit (5 MB).", {
          position: "top-center",
        });
        return;
      }
      // Create a FileReader object to read the file
      const reader = new FileReader();
      // Closure to capture the file information
      reader.onload = (e) => {
        // Push the preview URL to the urls array
        urls.push(e.target.result);
        // If all files have been processed, update the state
        if (urls.length === files.length) {
          setPreviewURL(urls);
        }
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const removePreviewImage = (index) => {
    const newPreviewURL = [...previewURL];
    newPreviewURL.splice(index, 1); // Remove the image at the specified index
    setPreviewURL(newPreviewURL); // Update the state with the new array
  };

  const handleUpdateEvent = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', pdfFile);
    formData.append('pdf', finalPdf);
    formData.append('title', finalTitle);
    formData.append('shortDesc', finalShortDesc);
    formData.append('price', finalPrice);
    formData.append('category', finalCategory);
    formData.append('productId', eventID);


    axios.post('https://roophix.com/backend/php/editProduct.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      setLoading(false);
      console.log(response.data);
      setFinalPdf(response.data.new_pdf)
      if (response.data.success === true) {
        toast.success('Category product updated successfully', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else {
        toast.error('Error updating category', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error('There was an error!', error);
    });
  }


  const renderHTMLShort = () => {
    return { __html: filteredEvents[0]?.shortDesc };
  };

  const deleteEvent = async (productId, id) => {
    if (window.confirm(`Are you sure you want to delete image?`)) {
      setLoading(true)
      const formData = new FormData();
      formData.append('producId', productId);
      formData.append('id', id);

      axios.post('https://roophix.com/backend/php/deleteProductImages.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          setLoading(false)
          const data = response.data
          console.log(response.data)
          toast.success('Image deleted successfully', {
            position: "top-center",
            
          })
    })
        .catch((error) => {
          console.error("Error:", error);
          Swal.fire({
            icon: "error",
            title: "Error.\n",
            text: `${error.message}`,
            customClass: {
              container: "my-swal-container", // Replace 'my-swal-container' with your desired class name
            },
          });
          setLoading(false);
        });
    }
  }

  const addProductImages = () => {
    setLoading(true)
    if(!selectedFiles.length === 0){
      toast.error("Please select at least an image!", {
        position: "top-center",
      });
      setLoading(false);
    }else{
      const formData = new FormData();
      formData.append("productId", eventID);
      formData.append("title", filteredEvents[0]?.title);
      formData.append("category", filteredEvents[0]?.category);
      Array.from(selectedFiles).forEach((file) => {
        formData.append("images[]", file);
      });
      formData.append("timestamp", Date.now());
    


    axios.post('https://roophix.com/backend/php/addProductImages.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setLoading(false)
        const data = response.data
        console.log(data)
        setMediaLoader(false);
        setSelectedFiles([]);
        setPreviewURL("");
        setProductImagesModal(false);
        toast.success('Images added successfully', {
          position: "top-center",
          
        })
  })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(`${error.message}`, {
          position: "top-center",
        })
        setLoading(false);
      });
    }
  }

  return (
    <div>
    <center style={{marginTop:5}}>
    <Button color='red' onClick={() => openModalFun(filteredEvents[0]?.title, filteredEvents[0]?.shortDesc, filteredEvents[0]?.pdf, filteredEvents[0]?.price, filteredEvents[0]?.category)}>
    Edit Product
    </Button>

    <Button variant='outlined' onClick={() => setProductImagesModal(true)} color='red' className='ml-1'>
    Add Product Images
    </Button>
    </center>
    {filteredEvents[0]?.title ? (
        <>
          <div style={{marginTop:10}}>
          {productImages.length > 0 ? (
            <div style={{display:'flex', alignItems:'center', justifyContent:'center',gap:5, flexWrap:'wrap'}}>
            {productImages.map((image, index) => (
              <div key={index}>
              <img src={image.image} onClick={() => deleteEvent(eventID, image.id)} alt={filteredEvents[0]?.title} style={{height:250, width:250, objectFit:'cover', cursor:'pointer'}} />
              </div>
            ))}
            </div>
          ):(
            <div className="container mx-auto px-2">
            <img src={filteredEvents[0]?.image} alt={filteredEvents[0]?.title} className="w-full h-40 object-cover" />
            </div>      
          )}
          </div>

        <div className="container mx-auto px-2">   

        <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexWrap:'wrap', gap:5, marginTop:10}}>
     
        </div>

        <div>
        <span className='text-red-500 font-bold'>Short Descriptions</span>        
        <Typography className='text-gray-500'>
        <div className="rendered-content" dangerouslySetInnerHTML={renderHTMLShort()} />
      </Typography>
      </div>
     
        </div>
        </>
    ):(
        <div
        style={{
            display:'table', 
            margin:'auto',
            marginTop:10
        }}
        >
        
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        >
        <CircularProgress color="white" />
        </Backdrop>
        </div>
    )}

    <Dialog
    open={modalShow}
    size="lg"
    handler={() => setModalShow(false)}
    className="z-1"
  >
    <DialogBody
    style={{ 
  height: '70vh',
  overflow: 'auto',
  }}
    className="z-1"
    >
    <ToastContainer />
    <Grid container spacing={2}>
    <Grid item xs={12}>
      <Input
        color="blue"
        fullWidth
        label="Product Name"
        value={finalTitle}
        onChange={(e) => setFinalTitle(e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Select
        label="Product Category"
        color="blue"
        onChange={handleOnChangeProductCategory}
        value={finalCategory}
      >
        {categories?.map((cat, index) => (
          <Option key={index} value={cat}>
            {cat}
          </Option>
        ))}
      </Select>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Input
        color="blue"
        fullWidth
        label="Product Price"
        value={finalPrice}
        onChange={(e) => setFinalPrice(e.target.value)}
      />
    </Grid>
    <Grid item xs={12}>
      <ReactQuill
        theme="snow"
        value={finalShortDesc}
        onChange={setFinalShortDesc}
        modules={{
          toolbar: toolbarOptions,
        }}
        formats={[
          "font", // Register font format
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "color",
          "background",
          "list",
          "bullet",
          "link",
          "image",
          "video",
          "audio",
          "align",
          "script",
          "font",
        ]}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        color="blue"
        type="file"
        label="Select PDF"
        accept=".pdf"
        onChange={(e) => setPdfFile(e.target.files[0])}
      />
    </Grid>

    <Grid item xs={12}>
    <Button color="blue" onClick={() => setShowModal(true)} variant='outlined' fullWidth>
      View PDF
    </Button>

    <Dialog
    open={showModal}
    size="lg"
    handler={() => setShowModal(false)}
    className="z-1"
  >
    <iframe src={finalPdf} title="PDF Viewer" className="w-full h-full" />
  </Dialog>
  </Grid>

    {/* Add product button */}
    <Grid item xs={12}>
      <Button color="blue" fullWidth onClick={handleUpdateEvent}>
        Edit Product
      </Button>
    </Grid>
  </Grid>
  
  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={loading}
>
  Updating...<CircularProgress color="inherit" />
</Backdrop>
    </DialogBody>
  </Dialog>

  <Dialog
  open={productImagesModal}
  size="lg"
  handler={() => setProductImagesModal(false)}
  className="z-1"
>
  <DialogBody
  style={{ 
height: '65vh',
overflow: 'auto',
}}
  className="z-1"
  >
  <ToastContainer />
<Grid spacing={2}>
<Grid item xs={12}>
<div>
  <Input
    color="blue"
    type="file"
    label="Select Images"
    onChange={handleFileSelect}
    accept="image/*"
    multiple // Allow multiple file selection
  />
</div>
{/* Preview images */}
<div
  style={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  {previewURL &&
    previewURL.map((url, index) => (
      <div key={index}>
        <img
          onClick={() => removePreviewImage(index)}
          src={url}
          alt={`Preview ${index}`}
          style={{
            width: 150,
            height: 150,
            objectFit: "cover",
            margin: 2,
            cursor: "pointer",
          }}
        />
      </div>
    ))}
</div>
</Grid>

</Grid>

<Backdrop
sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
open={loading}
>
Adding...<CircularProgress color="inherit" />
</Backdrop>
  </DialogBody>
  <DialogFooter>
<Button color="blue" onClick={addProductImages} fullWidth>
  Add Product Image(s)
</Button>
</DialogFooter>
</Dialog>
    </div>
  )
}

export default Overview