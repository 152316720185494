import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { AppBar, Autocomplete, Backdrop, Box, Card, CircularProgress, Dialog, Grid, IconButton, List, Slide, TextField, Toolbar } from "@mui/material";
import SoftBox from "../../../soft-components/SoftBox";
import SoftTypography from "../../../soft-components/SoftTypography";
import MiniStatisticsCard from "../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button,
  Typography,
  Select,
  Option,
  Textarea,
  Dialog as Dialog1,
  DialogBody,
  Spinner,
  Input
} from "@material-tailwind/react";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from "sweetalert2";
import axios from "axios";
import TotalProductsCategory from "./TotalProductsCategory";
import TotalProducts from "./TotalProducts";


const time = [
  "8:00 AM - 9:00 AM",
  "10:00 AM - 11:00 AM",
  "12:00 PM - 1:00 PM",
  "2:00 PM - 3:00 PM",
  "4:00 PM - 5:00 PM",
];

const disabledDates = [
  [0, 1],          // January 1st - New Year's Day
  [3, 15],            // Variable date - Good Friday
  [3, 18],          // Variable date - Easter Monday
  [4, 1],              // May 1st - Labour Day
  [5, 1],            // June 1st - Madaraka Day
  [9, 20],           // October 20th - Mashujaa Day
  [11, 12],           // December 12th - Jamhuri Day
  [11, 25],         // December 25th - Christmas Day
  [11, 26] 
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const containerStyle = {
  backgroundImage:
    "linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('/media/images/uon-towers.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

function Dashboard() {
  const user = useSelector((state) => state.user);
  const commaNumber = require("comma-number");
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);

  React.useEffect(() => {
    const fetchProductCategories = async () => {
        try {
            const response = await fetch('https://roophix.com/backend/php/getProductCategory.php');
            const data = await response.json();
            setProductCategories(data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    // Fetch blogs initially
    fetchProductCategories();

    // Fetch blogs every 1 second
    const interval = setInterval(() => {
        fetchProductCategories();
    }, 1000); // 1000 milliseconds (1 second)

    // Clear the interval if the component unmounts
    return () => clearInterval(interval);
}, []);

React.useEffect(() => {
  const fetchProducts = async () => {
      try {
          const response = await fetch('https://roophix.com/backend/php/getProducts.php');
          const data = await response.json();
          setProducts(data);
      } catch (error) {
          console.error('Error fetching blogs:', error);
      }
  };

  // Fetch blogs initially
  fetchProducts();

  // Fetch blogs every 1 second
  const interval = setInterval(() => {
      fetchProducts();
  }, 1000); // 1000 milliseconds (1 second)

  // Clear the interval if the component unmounts
  return () => clearInterval(interval);
}, []);

  



  const date = new Date();
  let hours = date.getHours();
  let status =
    hours < 12
      ? "Good Morning"
      : hours >= 12 && hours < 16
      ? "Good Afternoon"
      : hours >= 16 && hours < 19
      ? "Good Evening"
      : hours >= 19 && hours < 12
      ? "Good Night"
      : hours <= 12 && hours >= 12
      ? "Good Morning"
      : "Good Night";

  const pageTitle = "Admin Dashboard Page | Roophix";
  const pageDescription = "Admin Dashboard Page for Roophix";




  return (
    <div className="bg-darkblue h-screen p-3" style={containerStyle}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />

        {/* Open Graph meta tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />

        {/* Twitter Card meta tags */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 5,
        }}
      >
        <div>
          <span style={{ fontSize: 14 }} className="text-gray-300 font-bold">
            {status}, Admin
          </span>
        </div>
        <div className="flex gap-1">
        <Button color="blue">Logout</Button>
        </div>
      </div>

      <SoftTypography>
        <SoftBox py={1}>
          <SoftBox mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} xl={3} style={{ cursor: "pointer" }}>
                <MiniStatisticsCard
                  title={{ text: "Total Categories" }}
                  count={commaNumber(productCategories.length)}
                  percentage={{ color: "success", text: "+5%" }}
                  icon={{
                    color: "info",
                    component: "assignment",
                  }}
                />
              </Grid>

              <Grid style={{ cursor: "pointer" }} item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                  title={{ text: "Total Products" }}
                  count={commaNumber(products.length)}
                  percentage={{ color: "success", text: "+55%" }}
                  icon={{ color: "info", component: "group" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={3} style={{ cursor: "pointer" }}>
                <MiniStatisticsCard
                  title={{ text: "Shoppings" }}
                  count={commaNumber(1515)}
                  percentage={{ color: "success", text: "+3%" }}
                  icon={{ color: "info", component: "speaker" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={3} style={{ cursor: "pointer" }}>
                <MiniStatisticsCard
                  title={{ text: "Technical Insights" }}
                  count={commaNumber(5156)}
                  percentage={{ color: "error", text: "-2%" }}
                  icon={{ color: "info", component: "list_alt" }}
                />
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox>
            <Grid item>
              <Tabs value={0}>
                <TabsHeader>
                  <Tab key={0} value={0}>
                    Total Categories
                  </Tab>
                  <Tab key={1} value={1}>
                    Total Products
                  </Tab>
                  <Tab key={2} value={2}>
                    Shoppings
                  </Tab>
                  <Tab key={3} value={3}>
                    Technical Insights
                  </Tab>
                </TabsHeader>
                <TabsBody>
                  <TabPanel key={0} value={0}>
                    <TotalProductsCategory productCategories={productCategories}/>
                  </TabPanel>
                  <TabPanel key={1} value={1}>
                    <TotalProducts products={products}/>
                  </TabPanel>
                  <TabPanel key={2} value={2}>
                    bbb
                  </TabPanel>
                  <TabPanel key={3} value={3}>
                    aaa
                  </TabPanel>
                </TabsBody>
              </Tabs>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftTypography>


    </div>
  );
}

export default Dashboard;
