import React from 'react';

const ProductItem = ({ data }) => {
    const { primaryImg, secondaryImg, newClass, id, badge, title, price, oldPrice, category } = data;
    const [productImages, setProductImages] = React.useState([])


    React.useEffect(() => {
        const fetchProductsImages = async () => {
            try {
                const response = await fetch('https://roophix.com/backend/php/getProductsImages.php');
                const data = await response.json();
                const filteredImages = data.filter(item => item.product_id === parseInt(id));
                setProductImages(filteredImages);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
      
        // Fetch blogs initially
        fetchProductsImages();
      
        // Fetch blogs every 1 second
        const interval = setInterval(() => {
            fetchProductsImages();
        }, 1000); // 1000 milliseconds (1 second)
      
        // Clear the interval if the component unmounts
        return () => clearInterval(interval);
      }, []);

    return (
        <div className="product_item">
            <div className="product_thumb">
                <div className="product_imagebox">
                    <span className={newClass}>{badge}</span>
                    <img style={{height:150, objectFit:'cover'}} className="primary_img" src={productImages[0]?.image} alt="img" />
                    <img style={{height:150, objectFit:'cover'}} className="secondary_img" src={productImages[1]?.image} alt="img" />
                    <div className="cart_button">
                        <a href={`/product/${id}`} className="button effect_1">
                            View Product
                        </a>
                    </div>
                </div>
                <div className="product_item_inner">
                    <div className="label_text">
                        <h4 className="product_item_name"><a href={`/product/${id}`}>{title}</a> 
    {/*<span className="product_item_price"><del className={oldClass}>{oldPrice}</del> {price}</span>*/}
                        </h4>
                        <div className="product_category"><a href={`/product/${id}`}>{category}</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductItem;