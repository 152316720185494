import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './assets/css/style.css';
import Header from './Components/common/Header';
import Footer from './Components/common/Footer';
import Home01 from './Pages/Homes/Home01';
import About from './Pages/About';
import ContactPage from './Pages/ContactPage';
import Services01 from './Pages/Services/Services01';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import Admin from './Pages/Admin';
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/theme";
import ServiceDetails from './Pages/Services/ServiceDetails';
import Shop01 from './Pages/Shops/Shop01';
import Shop02 from './Pages/Shops/Shop02';
import ProductDetails from './Pages/Shops/ProductDetails';



function App() {
  const location = useLocation();
  return (
    <ThemeProvider theme={theme}>
    {location.pathname !== '/admin-page' &&(
      <Header />
    )}
     <Routes>
       <Route path="/" element={<Home01 />} />
       <Route path="/about-us" element={<About />} />
       <Route path="/contact-us" element={<ContactPage />} />  
       <Route path="/our-categories" element={<Services01 />} />
       <Route path="/product-category/:id" element={<ServiceDetails />} />
       <Route path="/admin-page" element={<Admin />} />
       <Route path="/our-products" element={<Shop02 />} />
        <Route path="/product/:id" element={<ProductDetails />} />
       <Route path="*" element={<ErrorPage />} />     
     </Routes>

     {location.pathname !== '/admin-page' &&(
      <Footer />
    )}      
    </ThemeProvider>
  )
}

export default App
