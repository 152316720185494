import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, List, Typography } from '@material-tailwind/react';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, useMediaQuery } from '@mui/material';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Option,
  Select,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import ReactQuill from 'react-quill'
import { FadeLoader } from 'react-spinners'
import 'react-quill/dist/quill.snow.css';
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const Align = ReactQuill.Quill.import('attributors/style/align');
ReactQuill.Quill.register(Align, true);


const toolbarOptions = [
  [{ 'font': [] }], // Font family options
  [{ 'header': [1, 2, 3, 4, 5, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['link'],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'align': [] }], // Alignment options
  ['clean'],
];


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'blue',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Overview({ eventID, productCategories }) {
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState('');
    const [previewURL, setPreviewURL] = useState('');
    const [medialLoader, setMediaLoader] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [finalDate, setFinalDate] = React.useState(dayjs());
    const [finalStartTime, setFinalStartTime] = React.useState('');
    const [finalEndTime, setFinalEndTime] = React.useState('');
    const [finalLocation, setFinalLocation] = React.useState('');
    const [finalCapacity, setFinalCapacity] = React.useState('');
    const [finalOpened, setFinalOpened] = React.useState(0);
    const [finalTitle, setFinalTitle] = React.useState('');
    const [finalDescription, setFinalDescription] = React.useState('');
    const [finalShortDesc, setFinalShortDesc] = React.useState('');

    const handleOnChangeOpened = (opened) => {
      setFinalOpened(opened);
    }

    const openModalFun = (title, shortDesc, longDesc) => {
      setModalShow(true);
      setFinalTitle(title);
      setFinalDescription(longDesc);
      setFinalShortDesc(shortDesc)
    }

  
  const filteredEvents = productCategories.filter(event => event.id === parseInt(eventID));

  const renderHTML = () => {
    return { __html: filteredEvents[0]?.description };
  };

  


  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFile(file)
    setMediaLoader(true)
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewURL(e.target.result);
      
    };
    reader.readAsDataURL(file);
};

  const handleUpdateEvent = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('image', filteredEvents[0]?.image);
    formData.append('productCategoryId', eventID);
    formData.append('title', finalTitle);
    formData.append('description', finalDescription);
    formData.append('shortDesc', finalShortDesc);
    axios.post('https://roophix.com/backend/php/editProductCategory.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      setLoading(false);
      console.log(response.data);
      if (response.data.success === true) {
        toast.success('Category product updated successfully', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setModalShow(false);
      } else {
        toast.error('Error updating category', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error('There was an error!', error);
    });
  }


  const renderHTMLShort = () => {
    return { __html: filteredEvents[0]?.shortDesc };
  };

  return (
    <div>
    {filteredEvents[0]?.title ? (
        <>
        <figure className="relative h-96 w-full">
        <img
          className="h-full w-full rounded-xl object-cover object-center"
          src={filteredEvents[0]?.image}
          alt={filteredEvents[0]?.title}
        />
        <div className="absolute inset-0 gradient"></div>
      </figure>

        <div className="container mx-auto px-2">   

        <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexWrap:'wrap', gap:5, marginTop:10}}>

     <div>
     <Button color='red' onClick={() => openModalFun(filteredEvents[0]?.title, filteredEvents[0]?.shortDesc, filteredEvents[0]?.description)} fullWidth>
     Edit Event
     </Button>
     </div>
     
        </div>

        <div>
        <span className='text-red-500 font-bold'>Short Descriptions</span>        
        <Typography className='text-gray-500'>
        <div className="rendered-content" dangerouslySetInnerHTML={renderHTMLShort()} />
      </Typography>
      </div>
      <div>
      <span className='text-red-500 font-bold mt-20'>Long Descriptions</span>
      <Typography className='text-gray-300'>
      <div className="rendered-content" dangerouslySetInnerHTML={renderHTML()} />
    </Typography>
      </div>
     
        </div>
        </>
    ):(
        <div
        style={{
            display:'table', 
            margin:'auto',
            marginTop:10
        }}
        >
        
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        >
        <CircularProgress color="white" />
        </Backdrop>
        </div>
    )}

    <Dialog
    open={modalShow}
    size="lg"
    handler={() => setModalShow(false)}
    className="z-1"
  >
    <DialogBody
    style={{ 
  height: '80vh',
  overflow: 'auto',
  }}
    className="z-1"
    >
    <ToastContainer />
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div className="lg:flex">
      <div className='lg:w-[50%]'>
      <center>
      {previewURL ? (
        <img alt={finalTitle} src={previewURL} className="cursor-pointer w-50 h-50 mb-4 lg:mr-4" />

          ):(
            <img alt={finalTitle} src={filteredEvents[0]?.image} className="cursor-pointer w-50 h-50 mb-4 lg:mr-4" />
          )}

      </center>
        <center className="text-[#000] font-bold">{filteredEvents[0]?.title}</center>
        <center className="mt-1 mb-5">
        <Input
        type="file"
        accept="image/*"
        color="blue"
        label="Select Image"
        onChange={handleFileSelect}
      />
        </center>
      </div>
  
     
      <div className='lg:ml-5'>
      <Grid container spacing={2}>
      <Grid item xs={12}>
      <Input color="blue" fullWidth label="Event Title"
      value={finalTitle}
      onChange={(e) => setFinalTitle(e.target.value)}
      />
      </Grid>

      <Grid item xs={12}>
      <ReactQuill theme="snow" 
      value={finalShortDesc}
      onChange={setFinalShortDesc}
      modules={{
        toolbar: toolbarOptions,
      }}
      formats={[
        'font', // Register font format
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'list',
        'bullet',
        'link',
        'image',
        'video',
        'audio',
        'align',
        'script',
        'font',
      ]}
      />
      </Grid>

        <Grid item xs={12}>
        <ReactQuill theme="snow" 
        value={finalDescription}
        onChange={setFinalDescription}
        modules={{
          toolbar: toolbarOptions,
        }}
        formats={[
          'font', // Register font format
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'color',
          'background',
          'list',
          'bullet',
          'link',
          'image',
          'video',
          'audio',
          'align',
          'script',
          'font',
        ]}
        style={{height:180}}
        />
        </Grid>
      </Grid>
      </div>
    </div>
  
  </div>   
  
  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={loading}
>
  Updating...<CircularProgress color="inherit" />
</Backdrop>
    </DialogBody>
    <DialogFooter
    style={{ background: "rgb(26, 32, 53)" }}
    >
    <Button
    color="blue"
    onClick={handleUpdateEvent}
    fullWidth
  >
    Update Event
  </Button>
    </DialogFooter>
  </Dialog>
    </div>
  )
}

export default Overview