import React, { useState } from 'react';
import ListProductItem from '../../Components/ShopElements/ListProductItem';
import PaginationDiv02 from '../../Components/ShopElements/PaginationDiv02';
import ProductItem from '../../Components/ShopElements/ProductItem';

const TabContent = () => {
    const [productData, setProductData] = useState([])

    React.useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://roophix.com/backend/php/getProducts.php');
                const data = await response.json();
                setProductData(data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
      
        // Fetch blogs initially
        fetchProducts();
      
        // Fetch blogs every 1 second
        const interval = setInterval(() => {
            fetchProducts();
        }, 1000); // 1000 milliseconds (1 second)
      
        // Clear the interval if the component unmounts
        return () => clearInterval(interval);
      }, []);
      

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                <div className="product_view_type">
                    <div className="product_view_grid product_col_3 type_1">
                        {
                            productData.map(data => <ProductItem
                                key={data.id}
                                data={data}
                            />)
                        }

                    </div>

                    { /* <PaginationDiv02 />*/}
                </div>
            </div>

        </div>
    );
};

export default TabContent;