import { Divider, IconButton, InputBase, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button,
  Typography,
  Select,
  Option,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from "sweetalert2";
import axios from "axios";
import ReactQuill from "react-quill";
import { FadeLoader } from "react-spinners";
import "react-quill/dist/quill.snow.css";
import {
  AppBar,
  Autocomplete,
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Grid,
  List,
  Slide,
  TextField,
  Toolbar,
} from "@mui/material";
import { useSelector } from "react-redux";
import AllProductCategories from "./AllProductCategories";

const Align = ReactQuill.Quill.import("attributors/style/align");
ReactQuill.Quill.register(Align, true);

const toolbarOptions = [
  [{ font: [] }], // Font family options
  [{ header: [1, 2, 3, 4, 5, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }], // Alignment options
  ["clean"],
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const containerStyle = {
  backgroundImage:
    "linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('/media/images/uon-towers.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

function TotalProductsCategory({ productCategories }) {
  const [openModal, setOpenModal] = React.useState(false);
  const [title, setTitle] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = React.useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [medialLoader, setMediaLoader] = useState(false);

  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState(() => {
    const savedSearchTerm = localStorage.getItem("searchTerm");
    return savedSearchTerm || "";
  });
  const [filteredPosts, setFilteredPosts] = useState(() => {
    const savedFilteredPosts = localStorage.getItem("filteredPosts");
    return savedFilteredPosts ? JSON.parse(savedFilteredPosts) : [];
  });




  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setMediaLoader(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewURL(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const addProductCategory = async () => {
    setLoading(true);
    if (!title) {
      toast.error("Please enter title", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!shortDesc) {
      toast.error("Please enter short description", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!description) {
      toast.error("Please enter description", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!file) {
      toast.error("Please select image!", {
        position: "top-center",
      });
      setLoading(false);
    } else {
        const formData = new FormData();
        formData.append('title', title)
        formData.append('shortDesc', shortDesc)
        formData.append('description', description)
        formData.append('file', file)
        formData.append('timestamp', Date.now())
        axios.post('https://roophix.com/backend/php/addProductCategory.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          setLoading(false)
          console.log('Response:', response.data)
          setTitle('')
          setDescription('')
          setFile('')
          setPreviewURL('')
          setShortDesc('')
          setOpenModal(false)
          setMediaLoader(false)
          Swal.fire({
            icon:'success',
            title:'Category Added Successfully!',
          })
        }).catch((error) => {
          setLoading(false)
          console.error('Error adding event:', error);
          toast.error("Error adding event!", {
            position: "top-center",
          });
        })
    }
  };

      // Update filtered posts when search term changes
      useEffect(() => {
        if (searchTerm === '') {
          setFilteredPosts([]);
        } else {
          const filteredData = productCategories.filter((post) =>
            post.title.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setFilteredPosts(filteredData);
        }
      }, [searchTerm]);
    
       // Save the searchTerm and filteredPosts to localStorage when they change
       useEffect(() => {
        localStorage.setItem("searchTerm", searchTerm);
        localStorage.setItem("filteredPosts", JSON.stringify(filteredPosts));
      }, [searchTerm, filteredPosts]);
      
      const updateSearchResults = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        localStorage.setItem("searchTerm", JSON.stringify(value));
      };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 5,
          background: "#88888888",
          padding: 5,
          borderRadius: 8,
          marginTop: -30,
        }}
      >
        <input
          style={{ width: "100%", height: 35, outline: "none", padding: 5 }}
          placeholder="Search here by title..."
          onChange={updateSearchResults}
          value={searchTerm}
        />
        <Button color="blue" onClick={() => setOpenModal(true)}>
          ADD
        </Button>
      </div>
      <AllProductCategories productCategories={productCategories} filteredPosts={filteredPosts} searchTerm={searchTerm}/>
      <Dialog open={openModal} handler={() => setOpenModal(false)}>
        <DialogBody
          style={{
            height: "90vh",
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                color="blue"
                fullWidth
                label="Service Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                
              />
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={shortDesc}
                onChange={setShortDesc}
                modules={{
                  toolbar: toolbarOptions,
                }}
                formats={[
                  "font", // Register font format
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "color",
                  "background",
                  "list",
                  "bullet",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "align",
                  "script",
                  "font",
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                modules={{
                  toolbar: toolbarOptions,
                }}
                formats={[
                  "font", // Register font format
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "color",
                  "background",
                  "list",
                  "bullet",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "align",
                  "script",
                  "font",
                ]}
                style={{ height: 150 }}
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ marginTop: 75, marginBottom: 10 }}>
                <Input
                  color="blue"
                  type="file"
                  label="Select Media"
                  onChange={handleFileSelect}
                  accept="image/*"
                  style={{ color: "#fff" }}
                />
              </div>
              {previewURL ? (
                <img
                  src={previewURL}
                  alt="Preview"
                  style={{
                    maxWidth: 500,
                    width: "100%",
                    height: 200,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <>
                  {medialLoader && (
                    <center>
                      <FadeLoader color="#36d7b7" />
                    </center>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button color="blue" fullWidth onClick={addProductCategory}>
                Add Product Category
              </Button>
            </Grid>
          </Grid>
        </DialogBody>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          Adding...
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}

export default TotalProductsCategory;
