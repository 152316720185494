import { Divider, IconButton, InputBase, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button,
  Typography,
  Select,
  Option,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from "sweetalert2";
import axios from "axios";
import ReactQuill from "react-quill";
import { FadeLoader } from "react-spinners";
import "react-quill/dist/quill.snow.css";
import {
  AppBar,
  Autocomplete,
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Grid,
  List,
  Slide,
  TextField,
  Toolbar,
} from "@mui/material";
import { useSelector } from "react-redux";
import AllProducts from "./AllProducts";

const Align = ReactQuill.Quill.import("attributors/style/align");
ReactQuill.Quill.register(Align, true);

const toolbarOptions = [
  [{ font: [] }], // Font family options
  [{ header: [1, 2, 3, 4, 5, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }], // Alignment options
  ["clean"],
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const containerStyle = {
  backgroundImage:
    "linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('/media/images/uon-towers.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

function TotalProducts({ products }) {
  const [openModal, setOpenModal] = React.useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = React.useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [medialLoader, setMediaLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState(() => {
    const savedSearchTerm = localStorage.getItem("searchTerm");
    return savedSearchTerm || "";
  });
  const [filteredPosts, setFilteredPosts] = useState(() => {
    const savedFilteredPosts = localStorage.getItem("filteredPosts");
    return savedFilteredPosts ? JSON.parse(savedFilteredPosts) : [];
  });

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const urls = [];
    setSelectedFiles(files);

    // Check if files are selected
    if (files.length === 0) {
      toast.error("Please select at least one image.", {
        position: "top-center",
      });
      return;
    }

    // Loop through each selected file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Validate file type (optional)
      if (!file.type.startsWith("image/")) {
        toast.error("Please select only image files.", {
          position: "top-center",
        });
        return;
      }
      // Validate file size (optional)
      if (file.size > 5242880) {
        // 5 MB limit
        toast.error("Image size exceeds the limit (5 MB).", {
          position: "top-center",
        });
        return;
      }
      // Create a FileReader object to read the file
      const reader = new FileReader();
      // Closure to capture the file information
      reader.onload = (e) => {
        // Push the preview URL to the urls array
        urls.push(e.target.result);
        // If all files have been processed, update the state
        if (urls.length === files.length) {
          setPreviewURL(urls);
        }
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const removePreviewImage = (index) => {
    const newPreviewURL = [...previewURL];
    newPreviewURL.splice(index, 1); // Remove the image at the specified index
    setPreviewURL(newPreviewURL); // Update the state with the new array
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://roophix.com/backend/php/getProductCategory.php"
        );
        const data = await response.json();
        const uniqueTitles = Array.from(
          new Set(data?.map((item) => item?.title))
        ); // Assuming each item has a 'title' field
        setCategories(uniqueTitles);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to fetch categories");
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleOnChangeProductCategory = (category) => {
    setCategory(category);
  };

  const addProductCategory = async () => {
    setLoading(true);
    if (!title) {
      toast.error("Please enter title", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!category) {
      toast.error("Please select category", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!price) {
      toast.error("Please enter price", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!shortDesc) {
      toast.error("Please enter description", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!pdfFile) {
      toast.error("Please select pdf!", {
        position: "top-center",
      });
      setLoading(false);
    } else if (!selectedFiles.length === 0) {
      toast.error("Please select at least an image!", {
        position: "top-center",
      });
      setLoading(false);
    } else {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("category", category);
      formData.append("price", price);
      formData.append("shortDesc", shortDesc);
      formData.append("pdfFile", pdfFile);
      Array.from(selectedFiles).forEach((file) => {
        formData.append("images[]", file);
      });
      formData.append("timestamp", Date.now());

      axios
        .post("https://roophix.com/backend/php/addProduct.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          console.log("Response:", response.data);
          setTitle("");
          setShortDesc("");
          setFile("");
          setPreviewURL("");
          setShortDesc("");
          //   setOpenModal(false);
          setMediaLoader(false);
          setCategory("");
          setPrice("");
          setPdfFile("");
          setSelectedFiles([]);
          toast.success("Product added successfully!", {
            position: "top-center",
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error adding product:", error);
          toast.error("Error adding product!", {
            position: "top-center",
          });
        });
    }
  };

  // Update filtered posts when search term changes
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredPosts([]);
    } else {
      const filteredData = products.filter((post) =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPosts(filteredData);
    }
  }, [searchTerm]);

  // Save the searchTerm and filteredPosts to localStorage when they change
  useEffect(() => {
    localStorage.setItem("searchTerm", searchTerm);
    localStorage.setItem("filteredPosts", JSON.stringify(filteredPosts));
  }, [searchTerm, filteredPosts]);

  const updateSearchResults = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    localStorage.setItem("searchTerm", JSON.stringify(value));
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 5,
          background: "#88888888",
          padding: 5,
          borderRadius: 8,
          marginTop: -30,
        }}
      >
        <input
          style={{ width: "100%", height: 35, outline: "none", padding: 5 }}
          placeholder="Search here by title..."
          onChange={updateSearchResults}
          value={searchTerm}
        />
        <Button color="blue" onClick={() => setOpenModal(true)}>
          ADD
        </Button>
      </div>

      <AllProducts products={products} filteredPosts={filteredPosts} searchTerm={searchTerm}/>

      <Dialog open={openModal} handler={() => setOpenModal(false)}>
        <DialogBody
          style={{
            height: "90vh",
            overflowY: "auto",
          }}
        >
          <ToastContainer />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                color="blue"
                fullWidth
                label="Product Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label="Product Category"
                color="blue"
                onChange={handleOnChangeProductCategory}
                value={category}
              >
                {categories?.map((cat, index) => (
                  <Option key={index} value={cat}>
                    {cat}
                  </Option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                color="blue"
                fullWidth
                label="Product Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={shortDesc}
                onChange={setShortDesc}
                modules={{
                  toolbar: toolbarOptions,
                }}
                formats={[
                  "font", // Register font format
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "color",
                  "background",
                  "list",
                  "bullet",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "align",
                  "script",
                  "font",
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                color="blue"
                type="file"
                label="Select PDF"
                accept=".pdf"
                onChange={(e) => setPdfFile(e.target.files[0])}
              />
            </Grid>
            {/* Image selection input */}
            <Grid item xs={12}>
              <div>
                <Input
                  color="blue"
                  type="file"
                  label="Select Images"
                  onChange={handleFileSelect}
                  accept="image/*"
                  multiple // Allow multiple file selection
                />
              </div>
              {/* Preview images */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {previewURL &&
                  previewURL.map((url, index) => (
                    <div key={index}>
                      <img
                        onClick={() => removePreviewImage(index)}
                        src={url}
                        alt={`Preview ${index}`}
                        style={{
                          width: 150,
                          height: 150,
                          objectFit: "cover",
                          margin: 2,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Grid>
            {/* Add product button */}
            <Grid item xs={12}>
              <Button color="blue" fullWidth onClick={addProductCategory}>
                Add Product Category
              </Button>
            </Grid>
          </Grid>
        </DialogBody>
        {/* Backdrop for loading indicator */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          Adding...
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}

export default TotalProducts;
