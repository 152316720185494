import { Backdrop, CircularProgress, Grid, IconButton, List, TableCell, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import { Button } from '@material-tailwind/react';
import { useCopyToClipboard } from "usehooks-ts";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Select,
  Option,
  Input,
} from "@material-tailwind/react";
import Overview from './Overview';
import { format } from 'date-fns';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Post({ index, productId, title, timestamp, image, shortDesc, description, products, category}) {
  const [open, setOpen] = React.useState(false);
  const [productImages, setProductImages] = React.useState([])
  const commaNumber = require("comma-number");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const fetchProductsImages = async () => {
        try {
            const response = await fetch('https://roophix.com/backend/php/getProductsImages.php');
            const data = await response.json();
            const filteredImages = data.filter(item => item.product_id === parseInt(productId));
            setProductImages(filteredImages);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };
  
    // Fetch blogs initially
    fetchProductsImages();
  
    // Fetch blogs every 1 second
    const interval = setInterval(() => {
        fetchProductsImages();
    }, 1000); // 1000 milliseconds (1 second)
  
    // Clear the interval if the component unmounts
    return () => clearInterval(interval);
  }, []);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



    const deleteEvent = async () => {
      if (window.confirm(`Are you sure you want to delete product category title "${title}"?`)) {
        setLoading(true)
        const formData = new FormData();
        formData.append('productId', productId);

        axios.post('https://roophix.com/backend/php/deleteProduct.php', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            setLoading(false)
            const data = response.data
            console.log(response.data)
            Swal.fire({
                icon: "success",
                title: `${data.message}`,
                customClass: {
                  container: "my-swal-container", // Replace 'my-swal-container' with your desired class name
                },
              });
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire({
              icon: "error",
              title: "Error.\n",
              text: `${error.message}`,
              customClass: {
                container: "my-swal-container", // Replace 'my-swal-container' with your desired class name
              },
            });
            setLoading(false);
          });
      }
    }
  return (
    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
    <TableCell>
    <img src={productImages[0]?.image} alt={title} style={{ width: 40, height: 40, borderRadius: 5, cursor:'pointer' }} />
    </TableCell>

    <TableCell style={{ color: '#fff' }} align="right">{title}</TableCell>
    <TableCell style={{ color: '#fff' }} align="right">{category}</TableCell>
    <TableCell style={{ color: '#fff' }} align="right">{productImages.length}</TableCell>
    <TableCell style={{ color: '#fff' }} align="right">
    {format(new Date(parseInt(timestamp)), "do MMM yyyy - h:mm a")}
  </TableCell>
    <TableCell style={{ color: '#fff' }} align="right">
    <RemoveRedEyeIcon onClick={handleClickOpen} fontSize='medium' className='cursor-pointer mr-5'/> 
    <DeleteForeverIcon fontSize='medium' onClick={deleteEvent} className='cursor-pointer'/>
    </TableCell>


    <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
  >
    <AppBar sx={{ position: 'sticky', background:'rgb(33 150 243 / var(--tw-bg-opacity))', color:'#fff' }} className='bg-blue-500'>
      <Toolbar>
  
        <Typography sx={{ ml: 2, flex: 1, color:'#fff', fontWeight:'bold' }} variant="h6" component="div">
          {title}
        </Typography>
        <IconButton
          edge="start"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <List
    style={{
      background:'rgb(26, 32, 53)',
      height:'150vh'
    }}
    >
   <Overview eventID={productId} products={products} productImages={productImages}/>
    </List>
  

  </Dialog>

  <Backdrop
sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
open={loading}
>
Deleting...<CircularProgress color="inherit" />
</Backdrop>
  </TableRow>
  )
}

export default Post