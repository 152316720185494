import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ProductView = () => {
    const [productImages, setProductImages] = useState([]);
    const { id } = useParams();
    const [selectedImage, setSelectedImage] = useState(0);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProductsImages = async () => {
            try {
                const response = await fetch('https://roophix.com/backend/php/getProductsImages.php');
                const data = await response.json();
                const filteredImages = data.filter(item => item.product_id === parseInt(id));
                setProductImages(filteredImages);
                if (filteredImages.length > 0) {
                    setSelectedImage(filteredImages[0]); // Set the first image as the default selected image
                }
            } catch (error) {
                console.error('Error fetching product images:', error);
            }
        };

        fetchProductsImages();
    }, [id]); // Only refetch when `id` changes

    useEffect(() => {
        const fetchProductsImages = async () => {
            try {
                const response = await fetch('https://roophix.com/backend/php/getProducts.php');
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error fetching product images:', error);
            }
        };

        fetchProductsImages();
    }, [id]); // Only refetch when `id` changes

    const filteredProduct = products.filter(event => event.id === parseInt(id));

    const renderHTMLShort = () => {
        return { __html: filteredProduct[0]?.shortDesc };
      };

    return (
        <div className="section product_view">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 pe-lg-5">
                        <div className="product_left">
                            {selectedImage && (
                                <img src={selectedImage.image} alt="Selected Product" style={{height:300, width:'100%', objectFit:'contain'}} className="img-fluid" />
                            )}
                            <div className="thumbnails" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                {productImages.map((image, index) => (
                                    <img key={index} src={image.image} alt={`Thumbnail ${index}`}
                                         className={`thumbnail ${selectedImage === image ? 'active' : ''}`}
                                         onClick={() => setSelectedImage(image)}
                                         style={{ cursor: 'pointer', width: 100, height:80,objectFit:'cover', margin: 1 }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 ps-lg-0 pe-lg-5">
                        <div className="product_right">
                            <div className="product_info">
                                <div className="product_title"><h2>{filteredProduct[0]?.title}</h2></div>
                                <div className="product_rating">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-half-o"></i>
                                    <i className="fa fa-star-o"></i>
                                    <div className="product_rating_details">(5 Customer Reviews)</div>
                                </div>
                            </div>
{   /*                     <div className="product_price">
                            <span className="product_per_price"></span> $<span className="product_total_price">320.5</span>
                            </div>*/}
                            <div className="product_view_bottom_credential">
                            <ul className="px-0 mb-0">
                                <li className="categories"><span>Category:</span> <a>{filteredProduct[0]?.category}</a></li>
                            </ul>
                        </div>
                            <div className="product_description">
                            <div className="rendered-content" dangerouslySetInnerHTML={renderHTMLShort()} />
                            </div>
                            <div className="product_view_bottom">
                                <div className="product_quantity">
                                    <div className="pproduct_quantity_label">Quantity</div>
                                    <div className="product_quantity_inner">
                                        <span className="product_quantity_subtract">
                                            <i className="ion-android-remove"></i>
                                        </span>
                                        <input type="text" id="product_quantity_input" placeholder="0" value="1" />
                                        <span className="product_quantity_add">
                                            <i className="ion-android-add"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="wish_button">
                                    <a href="#"><i className="fa fa-heart-o"></i></a>
                                </div>
                                <div className="compare_button">
                                    <a href="#"><i className="ion-connection-bars"></i></a>
                                </div>
                            </div>

                            <div className="cart_button">
                                <a className="button" href="#" >Add to Cart</a>
                            </div>
                            <div className="product_social_share">
                                <ul>
                                    <li className="label_text">Share:</li>
                                    <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                    <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                    <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                    <li className="youtube"><a href="#"><i className="ion-social-youtube"></i></a></li>
                                    <li className="pinterest"><a href="#"><i className="ion-social-pinterest-outline"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductView;