import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Post from './Post';


function AllProductCategories({ productCategories, filteredPosts, searchTerm }) {

    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem("currentPage");
        return savedPage ? parseInt(savedPage, 10) : 1;
      });
    const [prevPage, setPrevPage] = useState(1);
    const pageSize = 10; // Number of posts per page

    const totalPages = Math.ceil(productCategories.length / pageSize);
  
    // Handle page change
    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
  
      // Save the currentPage to localStorage when it changes
      useEffect(() => {
        localStorage.setItem("currentPage", currentPage);
      }, [currentPage]);
  
  // Get the posts for the current page
  const getCurrentPosts = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return productCategories.slice(startIndex, endIndex);
  };
  
    useEffect(() => {
      // Save the current page before updating the data
      setPrevPage(currentPage);
    }, [productCategories]);
  
    useEffect(() => {
      // Set the current page back to its previous value after data update
      setCurrentPage(prevPage);
    }, [prevPage]);
  return (
    <div>
    <Paper sx={{ width: '100%', overflow: 'hidden', background:'transparent'  }}>
    <TableContainer sx={{ maxHeight: 440, background:'transparent'  }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
          <TableCell style={{minWidth:100,fontSize:13,background:'rgb(26, 32, 53)',fontWeight:"900",borderBottom: "2px solid #2a68af",color:"#fff"}}>Image</TableCell>
          <TableCell style={{minWidth:100,fontSize:13,background:'rgb(26, 32, 53)',fontWeight:"900",borderBottom: "2px solid #2a68af",color:"#fff"}} align="right">Title</TableCell>
          <TableCell style={{minWidth:100,fontSize:13,background:'rgb(26, 32, 53)',fontWeight:"900",borderBottom: "2px solid #2a68af",color:"#fff"}} align="right">Date Added</TableCell>
          <TableCell style={{minWidth:100,fontSize:13,background:'rgb(26, 32, 53)',fontWeight:"900",borderBottom: "2px solid #2a68af",color:"#fff"}} align="right">ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {searchTerm === '' ? (
               <>
               {productCategories?.length > 0 ? (
                getCurrentPosts().map((item, index) => (
                    <Post 
                    index={index}
                    productCategoryId={item?.id}
                    title={item?.title}
                    timestamp={item?.timestamp}
                    shortDesc={item?.shortDesc}
                    description={item?.description}
                    image={item?.image}
                    productCategories={productCategories}
                    />
                ))
               ):(
                <center style={{ display: 'table', margin: 'auto', fontSize: 15, fontWeight:'bold', color:'#fff' }}>Nothing!</center>
               )}
               </>
           ):(
            <>
            {filteredPosts?.length > 0 ? (
                filteredPosts.map((item, index) => (
                    <Post 
                    index={index}
                    productCategoryId={item?.id}
                    title={item?.title}
                    timestamp={item?.timestamp}
                    shortDesc={item?.shortDesc}
                    description={item?.description}
                    image={item?.image}
                    productCategories={productCategories}
                    />
                ))
               ):(
                <center style={{ display: 'table', margin: 'auto', fontSize: 15, fontWeight:'bold', color:'#fff' }}>Nothing!</center>
               )}
            </>
           )}
        </TableBody>
      </Table>
    </TableContainer>
    <center style={{display:'table',margin:'auto',marginTop:5,marginBottom:5}}>
    <Pagination
    count={totalPages}
    page={currentPage}
    onChange={handlePageChange}
    variant="outlined"
    shape="rounded"
    color="primary"
  />
    </center>
  </Paper>
    </div>
  )
}

export default AllProductCategories