import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  setDoc,
  doc,
  onSnapshot
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDMMvx1I7M4x7VG_DTdcdqAaJiERyZ1aUg",
    authDomain: "roophix-solutions.firebaseapp.com",
    projectId: "roophix-solutions",
    storageBucket: "roophix-solutions.appspot.com",
    messagingSenderId: "601965998216",
    appId: "1:601965998216:web:24dba724289de731d92f3d",
    measurementId: "G-HJG468FJTV"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, onSnapshot, setDoc,doc, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, getFirestore, query, getDocs, collection, where, addDoc, }