import React from 'react';

const ServiceCard02_01 = ({ data }) => {
    const { image, title, shortDesc, id } = data;

    const renderHTML = () => {
        return { __html: shortDesc};
      };

    return (
        <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="service_inner_block">
                <img src={image} alt="" />
                <div className="service_content">
                    <h4>{title}</h4>
                    <p><div className="rendered-content" dangerouslySetInnerHTML={renderHTML()} /></p>
                    <a href={`/product-category/${id}`}>READ MORE <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard02_01;