import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';


const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <footer className="footer">
            <div className="footer_above">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget footer_widget_padding">
                                <h4 className="widget_title">
                                    About Roophix
                                </h4>
                                <p>We are committed to exceptional service, high quality products and timely delivery within lead times. You can precisely rely on Roophix with your projects.</p>
                                <div className="subscribe">
                                    <form className="form-inline">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <input type="email" className="form-control" placeholder="Email Address" />
                                        </div>
                                        <button type="submit" className="btn">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="footer_widget footer_links">
                            <h4 className="widget_title">
                                Product Mix
                            </h4>
                            <div className="footer_nav">
                                <ul className="footer_menu">
                                <li className="menu-item"><a href="#">Personal Protective Equipment</a></li>
                                <li className="menu-item"><a href="#">Fasteners & Concrete Anchors</a></li>
                                    <li className="menu-item"><a href="#">Tools & Machines</a></li>
                                    <li className="menu-item"><a href="#">Hand Protection</a></li>
                                    <li className="menu-item"><a href="#">Lifelines</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                <h4 className="widget_title">
                                    Useful Links
                                </h4>
                                <div className="footer_nav">
                                    <ul className="footer_menu">
                                        <li className="menu-item"><a href="#">Work With Us</a></li>
                                        <li className="menu-item"><a href="#">Events</a></li>
                                        <li className="menu-item"><a href="#">Support</a></li>
                                        <li className="menu-item"><a href="#">Be a Distributer</a></li>
                                        <li className="menu-item"><a href="#">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">
                                    Address
                                </h4>
                                <ul>
                                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><span>Off Biason Road Nairobi, Kenya</span></li>
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i><a href='mailto:info@roophix.com'>info@roophix.com</a></li>
                                    <li><i className="fa fa-clock-o" aria-hidden="true"></i><span>Monday - Friday (9.00am - 9.00pm)</span></li>
                                </ul>
                                <div className="side_footer_social">
                                    <ul className="bottom_social">
                                        <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                        <li className="dribbble"><a href="#"><i className="ion-social-dribbble-outline"></i></a></li>
                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="footer_bottom_inner">
                        <div className="logo">
                            
                        </div>
                        <div className="copyright">
                            <p>&copy;{currentYear} Created with <FavoriteIcon className="text-red-700 font-medium"/> by <a target='__blank' className='font-bold' href="https://jessy-bandya.web.app/">Jessy Bandya</a> for a better future. All rights reserved.</p>
                        </div>
                        <div className="footer_nav_bottom">
                            <ul>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div className="totop">
                            <a href="#"><i className="ion-ios-arrow-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;