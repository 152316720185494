import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer03 from '../../Containers/ServiceContainers/ServiceContainer03';
import { useSearchParams } from 'react-router-dom';

const ServiceDetails = () => {
    const { id } = useSearchParams();
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Categories' routeName02='Roophix' heading='Product Category' />
            <ServiceContainer03 id={id}/>
        </>
    );
};

export default ServiceDetails;