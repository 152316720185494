import React, { useState } from 'react';
import Testimonial01 from '../Testimonials/Testimonial01';
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ServiceContainer03(){
    const { id } = useParams();
    const [productCategories, setProductCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        const fetchProductCategories = async () => {
            try {
                const response = await fetch('https://roophix.com/backend/php/getProductCategory.php');
                const data = await response.json();
                setProductCategories(data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
    
        // Fetch blogs initially
        fetchProductCategories();
    
        // Fetch blogs every 1 second
        const interval = setInterval(() => {
            fetchProductCategories();
        }, 1000); // 1000 milliseconds (1 second)
    
        // Clear the interval if the component unmounts
        return () => clearInterval(interval);
    }, []);

    const filteredData = productCategories.filter(data => data?.id === parseInt(id));

    const renderHTML = () => {
        return { __html: filteredData[0]?.description };
      };

    return (
        <>
         {productCategories.length !== 0 ?(
            <div className="main_wrapper">
            <div className="container">
                <div className="project_details section">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="project_details_inner">
                                <div className="post_img">
                                    <img style={{height:350, width:'100%', objectFit:'cover'}} src={filteredData[0]?.image} alt="blog" />
                                </div>
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">{filteredData[0]?.title}</h3>
                                    </div>
                                    <div className="fulltext">
                                    <div className="rendered-content" dangerouslySetInnerHTML={renderHTML()} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar">
{/*                                <div id="widgetnav" className="widget widget_menu">
                                    <div className="sidenav">
                                        <ul className="side_menu">
                                            <li className="menu-item active">
                                                <a href="/services">
                                                    <img src="images/services/cone_1.png" alt="" />
                                                    <img src="images/services/2-white.png" alt="" />
                                                    Architectural Design
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services-2">
                                                    <img src="images/services/9-blue.png" alt="" />
                                                    <img src="images/services/cone_2.png" alt="" />
                                                    Comertial Construction
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services">
                                                    <img src="images/services/cone_3.png" alt="" />
                                                    <img src="images/services/5-blue.png" alt="" />
                                                    Real Estate & Housing Construction
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services-2">
                                                    <img src="images/services/cone_4.png" alt="" />
                                                    <img src="images/services/1-white.png" alt="" />
                                                    Renewable energy plant
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services">
                                                    <img src="images/services/cone_5.png" alt="" />
                                                    <img src="images/services/11-white.png" alt="" />
                                                    Commertial Powerplant
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services-2">
                                                    <img src="images/services/cone_6.png" alt="" />
                                                    <img src="images/services/10-white.png" alt="" />
                                                    Commercial Blueprint
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services">
                                                    <img src="images/services/cone_7.png" alt="" />
                                                    <img src="images/services/7-white.png" alt="" />
                                                    Civil Engineering
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
         </div> */}

                                <div id="custom_2" className="widget widget_side_contact">
                                    <div className="side_contact">
                                        <div className="side_contact_top">
                                            <div className="side_contact_inner">
                                                <p>Contact Us Via</p>
                                                <h4>+254746749307</h4>
                                            </div>
                                        </div>
                                        <a href='/our-products' className="side_phone_inner">
                                            <div>View Our Products</div>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
         ):(
            <Backdrop
sx={{ color: '#fff',fontWeight:'bold', zIndex: (theme) => theme.zIndex.drawer + 1 }}
open={loading}
>
Loading...<CircularProgress color="inherit" />
</Backdrop>
         )}
        </>
    );
};