import React from 'react'
import { useSelector } from 'react-redux';
import Dashboard from './Dashboard'
import Login from './Login'

function Admin() {
    const authId = useSelector((state) => state.authId)


  return (
    <div>
      {authId === 'iaekyd7TuBTVZDOfF4IlmKbD2ec2' ?(
         <Dashboard />
      ):(
        <Login />
      )}
    </div>
  )
}

export default Admin
