import React, { useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Checkbox,
    Button,
  } from "@material-tailwind/react";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase';
import { updateAuthId } from '../../../redux/dataSlice';

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();


    const handleLogin = async() => {
        setLoading(true)
        if(!email){
            toast.error('Email is required!', {
                position: "top-center",
                
            })
            setLoading(false)
        }else if(!password){
            toast.error('Password is required!', {
                position: "top-center",
                
            })
            setLoading(false)
        }else{
            try {
                await signInWithEmailAndPassword(auth, email, password).then((auth) => {
                  dispatch(updateAuthId(auth?.user?.uid));
                  toast.success('Successfully Logged In!', {
                    position: "top-center",
                    
                })
                  setLoading(false);
                });
              } catch (err) {
                console.error(err);
                setLoading(false);
                toast.error(err.message, {
                    position: "bottom-center",
                    
                })
              }
        }
    }

  return (
    <div
    style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'        
    }}
    >
    
    <Card className="w-96">
    <CardHeader
      variant="gradient"
      color="red"
      className="mb-4 grid h-28 place-items-center"
    >
      <Typography variant="h3" color="white">
        Sign In
      </Typography>
    </CardHeader>
    <CardBody className="flex flex-col gap-4">
      <Input label="Email"
        type="email"
      value={email}
        onChange={(e) => setEmail(e.target.value)}
      size="lg" color='red' style={{color:'gray'}}/>
      <Input label="Password"
        type="password"
      value={password}
        onChange={(e) => setPassword(e.target.value)}
      size="lg" color='red' style={{color:'gray'}}/>
    </CardBody>
    <CardFooter className="pt-0">
      <Button onClick={handleLogin} variant="gradient" color='red' fullWidth>
        Sign In
      </Button>
      <Typography variant="small" className="mt-6 flex justify-center">
        Forgotten Password?
        <Typography
          variant="small"
          color="blue-gray"
          className="ml-1 font-bold"
        >
          Click Here!
        </Typography>
      </Typography>
    </CardFooter>
  </Card>
    </div>
  )
}

export default Login