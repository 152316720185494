import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import Partners02 from '../Components/Partners/Partners02';
import TopFeatures from '../Components/TopFeatures';
import Banner from '../Containers/Banner';
import ExperienceAbout from '../Containers/Experiences/ExperienceAbout';
import FunFactAbout from '../Containers/Funfacts/FunFactAbout';
import Team from '../Containers/Team';
import Testimonial01 from '../Containers/Testimonials/Testimonial01';

const About = () => {

    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='About Us' heading='Roophix Solutions' />

            <div className="main_wrapper">
                <TopFeatures />

                <ExperienceAbout />



             <div
             style={{
                marginBottom:40,
                marginTop:15
             }}
             >
             <Banner
             title='We are because You are. Roophix is proud to hear from you 24/7'
             heading='Call Us Anytime'
             phone='+254784002044'
             bannerType='banner type_3'
         />
             </div>

            </div>
        </>
    );
};

export default About;