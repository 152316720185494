import React, { useState } from 'react';
import ServiceCard02_01 from '../../Components/ServiceCards/ServiceCard02_01';
import PaginationDiv from '../../Components/TeamElements/PaginationDiv';
import CircularProgress from '@material-ui/core/CircularProgress';

const ServiceContainer01 = () => {
    const [productCategories, setProductCategories] = useState([]);
    const ServiceData01 = [
        {
            id: '1',
            img: 'images/services/s4.png',
            img02: 'images/services/Service_Hover_images/1.jpg',
            tag: 'HORIZONTAL LIFELINES',
            title: 'Builderrine is the safe, reliable & cost effective construction company.'
        },
        {
            id: '2',
            img: 'images/services/s5.png',
            img02: 'images/services/Service_Hover_images/2.jpg',
            tag: 'VERTICAL LIFELINES',
            title: 'Builderrine Construction Company Receives Industry Recognition.'
        },
        {
            id: '3',
            img: 'images/services/s7.png',
            img02: 'images/services/Service_Hover_images/3.jpg',
            tag: 'PERSONAL PROTECTIVE EQUIPMENT',
            title: 'Extraordinary projects demand a strategic and intelligent approach, finely.'
        },
        {
            id: '4',
            img: 'images/services/s6.png',
            img02: 'images/services/Service_Hover_images/4.jpg',
            tag: 'HAND PROTECTION EQUIPMENT',
            title: 'Builderrine is the safe, reliable & cost effective construction company.'
        },
        {
            id: '5',
            img: 'images/services/s4.png',
            img02: 'images/services/Service_Hover_images/5.jpg',
            tag: 'CARPENTRY TOOLS',
            title: 'Builderrine is proud to join other companies and organizations.'
        },
        {
            id: '6',
            img: 'images/services/s9.png',
            img02: 'images/services/Service_Hover_images/6.jpg',
            tag: 'WOODWORKING MACHINES',
            title: 'Builderrine is the safe, reliable & cost effective construction company.'
        },
        {
            id: '7',
            img: 'images/services/s11.png',
            img02: 'images/services/Service_Hover_images/8.jpg',
            tag: 'SPAREPARTS',
            title: 'Builderrine Construction Company Receives Industry Recognition.'
        },
        {
            id: '8',
            img: 'images/services/s4.png',
            img02: 'images/services/Service_Hover_images/5.jpg',
            tag: 'FASTENERS & CONNECTIONS',
            title: 'Extraordinary projects demand a strategic and intelligent approach, finely.'
        },
        {
            id: '9',
            img: 'images/services/s12.png',
            img02: 'images/services/Service_Hover_images/9.jpg',
            tag: 'CONCRETE ANCHORS & POST BASES',
            title: 'Builderrine is proud to join other companies and organizations.'
        },
    ];

    React.useEffect(() => {
        const fetchProductCategories = async () => {
            try {
                const response = await fetch('https://roophix.com/backend/php/getProductCategory.php');
                const data = await response.json();
                setProductCategories(data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
    
        // Fetch blogs initially
        fetchProductCategories();
    
        // Fetch blogs every 1 second
        const interval = setInterval(() => {
            fetchProductCategories();
        }, 1000); // 1000 milliseconds (1 second)
    
        // Clear the interval if the component unmounts
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="main_wrapper">
            <div className="section service services_inner_page">
                <div className="container">
                    <div className="row">
                        {
                            productCategories.length !== 0 ?(
                                <>
                                {productCategories.map(data =>
                                    <ServiceCard02_01
                                        key={data.id}
                                        data={data}
                                    />
                                )}
                                </>
                            ):(
                                <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >
                                <CircularProgress 
                                 style={{color:'#fff'}}
                                />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceContainer01;