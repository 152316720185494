import React from 'react';

const CategoryItem = ({ data }) => {
    const { title, classThree, item1, item2, item3, toolID, href } = data;

    return (
        <li className="category_item">
            <div className="category_item_inner">
                <div className="cat_name"><a href="#">{title}</a></div>
                <span className="cat_toggler">
                    <a href={href}>
                        <i className="fa fa-chevron-right"></i>
                    </a>
                </span>
            </div>
        </li>
    );
};

export default CategoryItem;