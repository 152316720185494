import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/configureStore";
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css';
import { SoftUIControllerProvider } from "./context";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
  <SoftUIControllerProvider>
  <BrowserRouter>
  <ToastContainer />
    <App />
  </BrowserRouter>
  </SoftUIControllerProvider>
  </PersistGate>
  </Provider>
);
